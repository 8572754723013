<template>
  <v-row class="mt-0 pt-0 px-5" justify="center">
    <v-col cols="12" class="py-0">
      <v-layout justify-center>
        <h2 class="color-text-main pt-4">ACTIVIDAD DE AUTOCOM</h2>
      </v-layout>
      <v-layout justify-center>
        <p class="mt-4">
          Este módulo permite consultar las acciones que se han realizado de
          <strong> TODAS LAS COMISIONES </strong> en un orden descendente
        </p>
      </v-layout>
    </v-col>

    <v-col cols="4">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="form.date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="mt-3"
            v-model="form.date"
            label="Seleccione un rango de fechas"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            outlined
          ></v-text-field>
        </template>
        <v-date-picker range v-model="form.date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> cancelar </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(form.date)">
            Seleccionar
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>

    <v-col cols="4">
      <v-autocomplete
        class="mt-3"
        v-model="form.clientId"
        item-value="id"
        item-text="name"
        :items="listClients"
        outlined
        label="Lista de cliente *"
        color="#034f79"
      ></v-autocomplete>
    </v-col>

    <v-col cols="4">
      <v-autocomplete
        class="mt-3"
        v-model="form.campaignId"
        item-value="id"
        item-text="name"
        :items="listCampaigns"
        outlined
        label="Lista de campañas *"
        color="#034f79"
      ></v-autocomplete>
    </v-col>

    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="listHistory"
        :loading="loading.table"
        loading-text="Cargando..."
      >
        <!-- Sin resultados de busqueda -->
        <template v-slot:no-results>
          <v-alert icon="mdi-information-outline" prominent text type="info">
            <b>No se encontraron resultados con: {{ search }}</b>
          </v-alert>
        </template>
        <!-- Sin Data -->
        <template v-slot:no-data>
          <v-alert icon="mdi-information-outline" prominent text type="info">
            <b>No se encontró información.</b>
          </v-alert>
        </template>
        <!-- Loading -->
        <template v-slot:progress>
          <v-progress-linear
            color="#214484"
            :height="5"
            indeterminate
            stream
          ></v-progress-linear>
        </template>
        <!-- Status -->
        <template v-slot:item.statusVal="{ item }">
          <v-chip
            label
            dark
            small
            :color="getColorStatus(item.commissions.status_id)"
          >
            {{ item.commissions.status.name }}
          </v-chip>
        </template>

        <template v-slot:item.approval="{ item }">
          <v-tooltip v-if="item.approval == 1" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                label
                small
                color="green"
                class="mr-3"
                dark
                >APROBADO</v-chip
              >
            </template>
            <span>Aprobado</span>
          </v-tooltip>

          <v-tooltip v-if="item.approval == 0" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                label
                small
                color="#b71c1c"
                class="mr-3"
                dark
              >
                <strong> NO APROBADO </strong>
              </v-chip>
            </template>
            <span>No aprobado</span>
          </v-tooltip>
        </template>

        <template v-slot:item.url="{ item }">
          <v-btn color="#E95233" @click="download(item.url)" text small>
            <u> Descargar</u>
          </v-btn>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            color="#E95233"
            @click="irDetails(item.commission_id)"
            fab
            icon
            small
          >
            <v-icon> mdi-arrow-right </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>

    <!-- <v-col cols="12">
      <template>
        <v-timeline dense clipped> -->
    <!-- GRIS #BDBDBD -->
    <!-- <v-timeline-item
            v-for="(data, i) in listHistory"
            :key="'i' + i"
            :color="data.color"
            class="mb-4 text-left"
            small
          >
            <v-row align="center" justify="space-between">
              <v-col cols="7" style="color: #6f6f6f">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      class="white--text ml-0"
                      color="#034f79"
                      label
                      small
                    >
                      {{ data.user.document_number }}
                    </v-chip>
                  </template>
                  <span>{{ data.user.name }}</span>
                </v-tooltip>
                <strong> Comentario: </strong>
                <template v-if="data.comments != null">
                  {{ data.comments }}
                </template>
                <template v-else> <small> sin comentario </small> </template>
                <strong> Camapaña: </strong> -->
    <!-- <v-chip class="white--text ml-0" color="#034f79" label small> -->
    <!-- {{ data.commission_users.commissions.campaign.name }}. -->
    <!-- </v-chip> -->
    <!-- <strong> Centro de costos: </strong> -->
    <!-- <v-chip class="white--text ml-0" color="#034f79" label small> -->
    <!-- {{ data.commission_users.commissions.cost_center.name }}. -->
    <!-- </v-chip> -->
    <!-- <strong> Comisión: </strong>
                {{ data.commission_users.commissions.name }}
                <v-btn
                  color="#E95233"
                  @click="download(data.commision_url)"
                  text
                  small
                >
                  <u> Descargar comisión </u>
                </v-btn>
                <strong> Monto de la comisión: </strong>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <strong
                      v-bind="attrs"
                      v-on="on"
                      style="color: #e95233; font-size: 18px"
                    >
                      {{ validateCOP(data.commission_amount) }}
                    </strong>
                  </template>
                  <span>Monto total de la comisión</span>
                </v-tooltip>

                <v-btn
                  color="#034f79"
                  @click="download(data.variables_url)"
                  text
                  small
                >
                  <u> Descargar variable </u>
                </v-btn>
                <strong> Monto de la comisión: </strong>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <strong
                      v-bind="attrs"
                      v-on="on"
                      style="color: #034f79; font-size: 18px"
                    >
                      {{ validateCOP(data.variable_amount) }}
                    </strong>
                  </template>
                  <span>Monto total de las variables</span>
                </v-tooltip>
              </v-col>
              <v-col class="text-right" cols="5">
                <template
                  v-if="data.commission_users.action_id != ACTIONS.workforce"
                >
                  <v-tooltip v-if="data.approval == 1" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        label
                        small
                        color="green"
                        class="mr-3"
                        dark
                        >APROBADO</v-chip
                      >
                    </template>
                    <span>Aprobado</span>
                  </v-tooltip>
                  <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        label
                        small
                        color="#b71c1c"
                        class="mr-3"
                        dark
                      >
                        <strong> NO APROBADO </strong>
                      </v-chip>
                    </template>
                    <span>No aprobado</span>
                  </v-tooltip>
                </template>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      class="white--text ml-0"
                      :color="
                        getColorStatus(
                          data.commission_users.commissions.status_id
                        )
                      "
                      label
                      small
                    >
                      {{ data.commission_users.commissions.status.name }}
                    </v-chip>
                  </template>
                  <span>Estado de la comisión</span>
                </v-tooltip>
                {{ new Date(data.created_at).toISOString().substr(0, 10) }}
                <v-btn
                  color="#E95233"
                  @click="irDetails(data.commission_users.commissions.id)"
                  fab
                  icon
                  small
                >
                  <v-icon> mdi-arrow-right </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </template>
    </v-col> -->

    <v-overlay :value="loading.all">
      <v-progress-circular
        color="#e95233"
        width="8"
        indeterminate
        size="100"
      ></v-progress-circular
      ><br />
      <v-layout justify-center>
        <label class="mt-4" style="font-size: 20px">Cargando...</label>
      </v-layout>
    </v-overlay>
  </v-row>
</template>
<script>
import alert from "@/mixins/alert";
import color from "@/mixins/color";
import Api from "@/utils/api";
import { ACTIONS } from "@/utils/util";
export default {
  mixins: [alert, color],
  data() {
    return {
      ACTIONS: ACTIONS,
      // Agregar el action rol en la tabla
      // Revisar el documento que muestre si es variable o comisión
      // Revisar el monto que muestre si es variable o comisión
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Cliente", value: "client_name" },
        { text: "Campaña", value: "campaign_name" },
        { text: "Tipo de archivo comisión/variable", value: "typeV" },
        { text: "Documento comisión/variable", value: "url" },
        { text: "Monto comisión/variable", value: "amount" },
        { text: "Comentarios", value: "comments" },
        // { text: "Estados", value: "status_name" },
        { text: "Aprobación/Rechazo", value: "approval" },
        { text: "Fecha", value: "date" },
        { text: "Acciones", value: "actions" },
      ],
      form: {
        clientId: null,
        campaignId: null,
        date: null,
      },
      itemsPerPageArray: [5, 10, 20, 50],
      listHistory: [],
      listClients: [],
      listCampaigns: [],
      menu: false,
      date: null,
      alert1: {
        selected: true,
        info: false,
      },
      loading: {
        all: false,
        table: false,
      },
    };
  },
  watch: {
    "form.clientId": function (n) {
      this.getHistoryAll();
      this.getCampaign();
    },
    "form.campaignId": function (n) {
      this.getHistoryAll();
    },
    "form.date": function (n) {
      if (n.length == 2) {
        this.getHistoryAll();
      }
    },
  },
  methods: {
    validateCOP(value) {
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
      }).format(value);
    },
    download(data) {
      window.open(data, "_blank	");
    },
    irDetails(id) {
      this.$router.push({
        name: "commissionDetails",
        params: { commissionId: id },
      });
    },
    getColorStatus(status) {
      var color = this.color(status);
      return color;
    },
    getHistoryAll() {
      this.alert1.selected = false;
      this.loading.all = true;
      var data = {
        date_ini:
          this.form.date && this.form.date != undefined
            ? this.form.date[0]
            : null,
        date_fin: this.form.date ? this.form.date[1] : null,
        client_id: this.form.clientId ? this.form.clientId : null,
        campaign_id: this.form.campaignId ? this.form.campaignId : null,
      };
      Api.Auth()
        .getHistoryAll(data)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listHistory = res.data.data;
            for (let i = 0; i < this.listHistory.length; i++) {
              if (this.listHistory[i].commission_amount) {
                this.listHistory[i].typeV = "Comisión";
                this.listHistory[i].amount = this.validateCOP(
                  this.listHistory[i].commission_amount
                );
                this.listHistory[i].url = this.listHistory[i].commision_url;
              }
              if (this.listHistory[i].variable_amount) {
                this.listHistory[i].typeV = "Variable";
                this.listHistory[i].amount = this.validateCOP(
                  this.listHistory[i].variable_amount
                );
                this.listHistory[i].url = this.listHistory[i].variable_url;
              }
            }
            // var date1 = null;
            // var date2 = (date2 = new Date().toISOString().substr(0, 10));
            // for (let i = 0; i < this.listHistory.length; i++) {
            //   date1 = new Date(this.listHistory[i].created_at)
            //     .toISOString()
            //     .substr(0, 10);
            //   if (date1 == date2) {
            //     this.listHistory[i].color = "#FF302B";
            //   } else {
            //     this.listHistory[i].color = "#BDBDBD";
            //   }
            // }
            // if (this.listHistory.length == 0) {
            //   this.alert1.info = true;
            // } else {
            //   this.alert1.info = false;
            // }
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => (this.loading.all = false));
    },
    getClient() {
      this.loading.all = true;
      Api.Workforce()
        .getClients()
        .then((res) => {
          this.listClients = res.data.data;
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    getCampaign() {
      console.log(this.form)
      this.loading.all = true;
      Api.Workforce()
        .getCampaignClient(this.form.clientId)
        .then((res) => {
          this.listCampaigns = res.data.data;
        })
        .catch((error) => {
          this.steps = [];
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.all = false;
        });
        
      // this.loading.all = true;
      // Api.Auth()
      //   .getCampaign()
      //   .then((res) => {
      //     this.listCampaigns = res.data.data;
      //   })
      //   .catch((error) => {
      //     this.alert(error.response.data.message, "Oops...", "error");
      //   })
      //   .finally(() => {
      //     this.loading.all = false;
      //   });
    },
  },
  mounted() {
    this.getClient();
    // this.getCampaign();
    this.getHistoryAll();
  },
};
</script>
